<script setup>

import {useRouter} from "vue-router";
import {onMounted, reactive, ref} from "vue";
import {Notify, Toast} from "vant";
import {locationDevice, queryDevice, updateDevice} from "../../api/device";
import {queryCustomer, queryCustomerDepartment, queryCustomersPage} from "../../api/customer";
import dd from "gdt-jsapi";
const router = useRouter();
const query = getUrlParam();
const device = reactive({
  customer: {
    id:'',
    name:'',
    address: '',
    city:'',
    county: '',
    province: '',
    storey: '',
    customerDepartmentId:null,
  },
  dz:"",
  customerDepartment: {},
  departmentColumnsName:[],
  departmentColumnsID:[],
  customerid:''
});
const show = ref(false);
const showPopup = () => {
  show.value = true;
};
const createinformation = () => {
  show.value = false;
  router.push({query, path: "/customer/create-information"});
};

const editinformation = () => {
  show.value = false;
  query.customerid = device.customerid;
  console.log(query)
  router.push({query, path: "/customer/edit-information"});
};


const onConfirm = (value, index) => {
  console.log(device.departmentColumnsID[index])
  device.customerid = device.departmentColumnsID[index];
  device.customer.name =device.departmentColumnsName[index];
  show.value = false;
};
const onChange = (value, index) => {
  device.customerid = device.departmentColumnsID[index];
};
const onCancel = () =>{
  show.value = false;
}

const getDeviceLsit = () => {

  queryDevice(query.id).then((res)=>{
    device.dz = `${res.data.province}-${res.data.city}-${res.data.county}`
    device.customer = {
      storey: res.data.storey,
      customerDepartmentId:res.data.customerDepartmentId
    };
    getCustomerDepartment(res.data.customerDepartmentId) // 获取客户单位
    getCustomer(res.data.customerId) // 获取客户信息
    getCustomersPage(res.data.customerDepartmentId)// 获取客户列表
  });
}
const getCustomerDepartment = (id) => {
  queryCustomerDepartment(id).then((res)=>{
    device.customerDepartment = res.data;
  })
}

const getCustomer = (id) => {
  queryCustomer(id).then((res)=>{

    device.customerid = res.data.id;
    device.customer.name = res.data.name  +'('+ res.data.cellphone+')';
    device.customer.address = res.data.address
    device.customer.city = res.data.city
    device.customer.county = res.data.county
    device.customer.province = res.data.province

  })
}
const getCustomersPage = (id) => {
  queryCustomersPage({
    current: 1,
    pageSize: 100,
    departmentId: id
  }).then((res)=>{
    if(res.status === 20000){
      device.departmentColumnsName = res.data.map(item =>item.name+'('+item.cellphone+')');
      device.departmentColumnsID = res.data.map(item =>item.id);
    }
  });
}
const onSubmit = () => {
  let data = {
    id: query.id,
    customerId: device.customerid,
    storey: device.customer.storey,
    type:1
  }
  locationDevice(data).then(res=>{
    res.status === 20000 && Notify({ type: 'success', message: '修改成功' });
  })


};


onMounted(() => {
  getDeviceLsit()
  dd.hideTitleBar()
});

</script>

<template>
<div>
  <van-nav-bar fixed border left-arrow title="完善信息" left-text="返回" @click-left="router.back"/>
  <div class="page-container">
    <van-form @submit="onSubmit">
    <van-cell-group inset>
      <van-field label="客户单位" readonly v-model="device.customerDepartment.name"/>
      <van-field label="所在区县" readonly v-model="device.dz"/>
      <van-field label="责任人" readonly required :rules="[{ required: true, message: '请输入责任人姓名' }]" @click="showPopup" v-model="device.customer.name">
        <template #button>
          <van-button size="small" @click.stop="editinformation" v-if="device.customerid">
            <van-icon name="edit" color="#333" /> 编辑
          </van-button>
        </template>
      </van-field>
      <van-field label="设备所在地" required v-model="device.customer.storey" :rules="[{ required: true, message: '请输入设备所在楼号楼层及门牌号' }]" placeholder="如:市政中心北楼9楼917室" />
    </van-cell-group>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>

    <van-popup v-model:show="show" position="bottom" :style="{ height: '30%' }">
      <div class="addDept">
        <van-button type="primary" icon="plus" size="small" class="addbut" @click="createinformation">新增责任人</van-button>
        <van-picker
            title="-"
            :columns="device.departmentColumnsName"
            @confirm="onConfirm"
            @cancel="onCancel"
            @change="onChange"
        />
      </div>

    </van-popup>
  </div>
</div>
</template>

<style scoped>
  .addDept{
    position: relative;
  }
  .addbut{
    position: absolute;
    z-index: 9;
    right: 40%;
    top: 5px;
  }
</style>